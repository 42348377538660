/* istanbul ignore file */
import KatalLogger, { Level } from "@amzn/katal-logger";

/**
 * Generates or fetches a Browser Sessions tracking ID to associate logs from the same browser session.
 *
 * @return the tracking ID in the format of FMUI-UUID.
 */
function generateBrowserSessionTrackingId(): string {

  // Use the browser session to keep the value when the user refreshes, but doesn't close the tab.
  // The session storage is also cleared when someone signs out (this will be a new value)
  let browserSessionTrackingId = sessionStorage.getItem('browserSessionTrackingId');

  // Generate a tracking id if we don't have one in the browser session
  if (!browserSessionTrackingId) {
    browserSessionTrackingId = 'FMUI-xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      // Get a number 0-15
      const r = Math.random() * 16 | 0;

      // This manipulation ensures the UUID follows the version 4 UUID standard (RFC 4122),
      // where certain bits need to be set to specific values:
      // The first character after the third hyphen (the 'y' position) must be one of: 8, 9, A, or B
      // This is why we use the | 0x8 to ensure the first bit is 1, and & 0x3 to ensure the second bit is either 0 or 1
      const v = c === 'x' ? r : (r & 0x3 | 0x8);

      // Convert the number to a hexadecimal value
      return v.toString(16);
    });
    sessionStorage.setItem('browserSessionTrackingId', browserSessionTrackingId);
  }

  return browserSessionTrackingId;
}


const katalLoggerConfig = {
  url: "https://6brpecwc64.execute-api.us-east-1.amazonaws.com/prod/v1/log",
  logThreshold: Level.INFO,
  maxLogLineSize: 10000,
  logToConsole: "prod".toLowerCase() !== "prod",
  context: {
    'User-Agent': window.navigator.userAgent,
    // This is to make it easier to associate logs from the same browser session
    'browserSessionTrackingId':  generateBrowserSessionTrackingId(),
  }
};

export const logger = new KatalLogger(katalLoggerConfig);
logger.addErrorListener();
